<template>
  <div>
    <h1> SPID Auth </h1>
    <Spinner :status="showSpinner" />
    <Alert :i18nData="asyncError" />
  </div>
</template>

<script>
import InfoService from '@/services/InfoService'
import { logError } from '@/utils/error'
import {
  E_SERVER_HTTP
} from '@/constants'
import {
  SET_REDIRECT_URI
} from '@/store/mutationTypes'

export default {
  name: 'SPIDAuth',
  data () {
    return {
      asyncError: null,
      showSpinner: true
    }
  },
  methods: {
    getAccessToken (params) {
      const q = new URLSearchParams(params)
      window.location.href = `/spid-login?${ q.toString() }`
    },
    async login (redirectUri, consentUri) {
      return InfoService.fetchContractInfo().then(async info => {
        if (info && !info.latest_contract) {
          this.$store.commit(SET_REDIRECT_URI, consentUri)
          this.$router.push('accept-contract')
        } else {
          this.$store.commit(SET_REDIRECT_URI, redirectUri)
          this.$store.dispatch('goToRedirectUri')
        }
      }).catch(err => {
        this.showSpinner = false
        if (err.id === E_SERVER_HTTP) {
          this.asyncError = { key: 'pages.spidAuth.error' }
          logError(err)
        } else {
          throw err
        }
      })
    }
  },
  mounted () {
    if (this.$route.query.idp) {
      return this.getAccessToken(this.$route.query)
    }

    return this.login(this.$route.query.redirect_uri, this.$route.query.consent_uri)
  }
}
</script>
