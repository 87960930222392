import Vue from 'vue'
import Vuex from 'vuex'
import AuthService from '@/services/AuthService'
import {
  TYPE_PHONE,
  SMS_PIN_WARNING_THRESHOLD,
  VALIDATION_STATUS_ABUSED
} from '@/constants'

import {
  PIN_SENT,
  RECOVER_FINISHED,
  RECEIVED_COUNTRY_PREFIXES,
  SET_REDIRECT_URI,
  SHOW_MODAL,
  HIDE_MODAL,
  UPDATE_HAS_TEMP_PASSWORD
} from '@/store/mutationTypes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    recover: null,
    sentSMSPinCount: 0,
    smsPinAbused: false,
    countryPrefixes: [],
    currentCountryPrefix: {},
    redirectUri: null,
    modalVisible: false,
    modalComponent: null,
    modalProps: {},
    hasTempPassword: false
  },
  mutations: {
    [PIN_SENT] (state, recoverNewState) {
      state.recover = recoverNewState
    },
    'INCREMENT_SENT_SMS_PIN_COUNT' (state) {
      state.sentSMSPinCount++
    },
    [RECOVER_FINISHED] (state) {
      state.recover = null
    },
    [RECEIVED_COUNTRY_PREFIXES] (state, countryPrefixes) {
      state.countryPrefixes = countryPrefixes
    },
    'SET_CURRENT_COUNTRY_PREFIX' (state, countryPrefix) {
      state.currentCountryPrefix = countryPrefix
    },
    'SET_SMS_PIN_ABUSED' (state) {
      state.smsPinAbused = true
    },
    [SET_REDIRECT_URI] (state, newUri) {
      state.redirectUri = newUri
    },
    [SHOW_MODAL] (state, { componentName, props }) {
      Vue.set(state, 'modalProps', props)
      state.modalVisible = true
      state.modalComponent = componentName
    },
    [HIDE_MODAL] (state) {
      state.modalVisible = false
    },
    [UPDATE_HAS_TEMP_PASSWORD] (state, value) {
      state.hasTempPassword = value
    }
  },
  getters: {
    showSMSPinWarning (state) {
      return state.sentSMSPinCount >= SMS_PIN_WARNING_THRESHOLD
    }
  },
  actions: {
    async sendPin ({ commit }, { type, value }) {
      const guid = await AuthService.checkProxies({ type, value })
      const res = await AuthService.sendValidation({ type, value, guid })
      if (res.sent === VALIDATION_STATUS_ABUSED) {
        commit('SET_SMS_PIN_ABUSED')
      }
      commit(PIN_SENT, { type, value, guid })
      if (type === TYPE_PHONE) {
        commit('INCREMENT_SENT_SMS_PIN_COUNT')
      }
    },
    async checkValidation ({ state }, pin) {
      return AuthService.checkValidation({
        type: state.recover.type,
        value: state.recover.value,
        guid: state.recover.guid,
        code: pin
      })
    },
    async validationAction ({ state }, password) {
      return AuthService.validationAction({
        guid: state.recover.guid,
        password
      })
    },
    async getCountryPrefixes ({ commit }) {
      const countryPrefixes = await AuthService.getCountryPrefixes()
      commit(RECEIVED_COUNTRY_PREFIXES, countryPrefixes)
    },
    goToRedirectUri ({ state }) {
      if (state.redirectUri) {
        window.location.href = state.redirectUri
      }
    },
    cleanRecover ({ commit }) {
      commit(RECOVER_FINISHED)
    }
  }
})
