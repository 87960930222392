<template>
  <PrefixChoiceLayout v-slot="{ openList }" >
      <template v-if="productName === 'lyber'">
        <div class="mt-3">
          <ButtonBrand brand="google" to="/google-auth" :disabled="loading" />
        </div>
        <div class="separator my-4">
            <span class="label">{{ $t('pages.login.separator') }}</span>
        </div>
      </template>
      <Alert :i18nData="asyncError" />
      <template v-if="!isRiv && ((hostname() == 'oauth2-server') || ($route.path == '/spid'))">
        <div class="mt-3">
          <ButtonSPID to="#" :disabled="loading"/>
        </div>
        <div class="separator my-3">
            <span class="label">{{ $t('pages.login.separator') }}</span>
        </div>
      </template>
      <h5 class="my-4 title" v-if="!isRiv">{{ $t('pages.login.title') }}</h5>
      <Spinner :status="loading" />
      <div v-show="!loading">
        <Toggle
          id="input-type-toggle"
          name="contact-type"
          :optionsText="[$t('pages.login.radio.mobile'), $t('pages.login.radio.email')]"
          :optionsValues="loginOptions"
          v-model="loginType"
          v-if="!isRiv"
        />
        <b-form @submit.prevent="onSubmit" :class="{'my-5' : isRiv}">
          <b-form-group class="form-group-floating">
            <template v-if="isPhoneForm">
              <b-input-group size="lg">
                <b-input-group-text slot="prepend" class="bg-white">
                  <span class="fa-clickable d-flex align-items-center" @click="openList">
                    <b-img :src="`/img/flags/${currentCountryPrefix.code}@1x.png`" width="28" height="20" alt="prefix-flag"></b-img>
                    <span class="ml-2 text-muted">+{{ currentCountryPrefix.prefix }}</span>
                  </span>
                </b-input-group-text>
                <b-form-input
                  id="input-1"
                  key="1"
                  v-model.trim="form.login"
                  type="tel"
                  name="tel"
                  required
                  v-focus
                  class="floating-label-input"
                />
                <label for="input-1" class="tel-label">
                  {{ $t('pages.login.loginLabelPhone') }}
                </label>
              </b-input-group>
            </template>
            <template v-else>
              <b-form-input
                id="input-1"
                v-model.trim="form.login"
                type="text"
                required
                v-focus
                size="lg"
                class="floating-label-input"
              ></b-form-input>
              <label for="input-1">
                {{ isRiv ? 'Username' : $t('pages.login.loginLabelText') }}
              </label>
            </template>
          </b-form-group>
          <b-form-group class="form-group-floating">
            <b-input-group size="lg">
              <b-input
                id="input-2"
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                class="floating-label-input"
                required
              />
              <label for="input-2">
                {{ $t('pages.login.passwordLabel') }}
              </label>
              <b-input-group-text slot="append">
                <span class="fa-clickable" @click.prevent="showPassword = !showPassword">
                  <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
                </span>
              </b-input-group-text>
            </b-input-group>
            <div v-if="isRiv">
              <a class="recover-link d-block bottom-left-anchor my-1"
                role="button" @click="showInfoMessage=true">
                {{ $t('pages.login.linkRecoverPassword') }}
              </a>
              <b-alert :show="showInfoMessage" dismissible variant="info" class="mt-3"
                @dismissed="showInfoMessage=false">
                <span class="info-msg" v-html="$t('pages.login.recoverPassReseller', { supportEmail }) "/>
              </b-alert>
            </div>
            <router-link class="recover-link d-block small bottom-left-anchor my-1" to="/recover" v-else>
              {{ $t('pages.login.linkRecoverPassword') }}
            </router-link>
          </b-form-group>
          <b-button
            class="btn-centered rounded-pill submit-btn"
            type="submit"
            variant="primary"
            :disabled="loading"
          >
            <strong>{{ $t('pages.login.buttonOk') }}</strong>
          </b-button>
        </b-form>
      </div>
      <div v-if="urlNewUser" class="d-flex justify-content-center">
        <a :href="urlNewUser" class="link-centered new-user-link">{{ $t('pages.login.linkNewUser') }}</a>
      </div>
  </PrefixChoiceLayout>
</template>

<script>
import ButtonBrand from '@/elements/ButtonBrand.vue'
import ButtonSPID from '@/elements/ButtonSPID.vue'
import LoginService from '@/services/LoginService'
import PrefixChoiceLayout from '@/components/PrefixChoiceLayout'
import InfoService from '@/services/InfoService'
import {
  getProductName,
  buildSmartProvisioningUrl
} from '@/utils/url'
import {
  E_SERVER_HTTP,
  E_CODE_UNAUTHORIZED,
  E_CODE_BADUSER,
  TYPE_PHONE,
  TYPE_EMAIL,
  SUPPORT_EMAIL_ADDR,
  RESELLER_ROLE_TYPE
} from '@/constants'
import { SET_REDIRECT_URI, UPDATE_HAS_TEMP_PASSWORD } from '@/store/mutationTypes'
import { mapState } from 'vuex'
import Toggle from '@/elements/Toggle'
import { getFingerprintData } from '@/utils/fingerprint'
import { logError } from '@/utils/error'
import { turnOffZoom } from '@/utils'

export default {
  name: 'Login',
  components: {
    Toggle,
    ButtonBrand,
    ButtonSPID,
    PrefixChoiceLayout
  },
  data () {
    return {
      form: {
        login: '',
        password: '',
        fingerprint: {}
      },
      loginType:
        this.$route.query.product_name === 'mtalk-app'
          ? TYPE_PHONE
          : TYPE_EMAIL,
      asyncError: null,
      canClean: true,
      loading: false,
      showPassword: false,
      loginOptions: [TYPE_PHONE, TYPE_EMAIL],
      spidPath: 'public/spid/production/',
      showInfoMessage: false,
      supportEmail: SUPPORT_EMAIL_ADDR
    }
  },
  computed: {
    ...mapState(['currentCountryPrefix']),
    urlNewUser () {
      switch (this.productName) {
        case 'lyber':
          return process.env.VUE_APP_NEW_USER_LYBER
        case 'mnet':
          return process.env.VUE_APP_NEW_USER_MNET
        case 'smart':
        case 'snpro1':
        case 'snpro2':
        case 'snpro3':
          return buildSmartProvisioningUrl()
        case 'faxin':
          return process.env.VUE_APP_NEW_USER_FAX
        default:
          return null
      }
    },
    isPhoneForm () {
      return this.loginType === TYPE_PHONE
    },
    productName () {
      return getProductName()
    },
    formData () {
      if (this.isPhoneForm) {
        return {
          ...this.form,
          login: `+${this.currentCountryPrefix.prefix + this.form.login}`
        }
      } else {
        return this.form
      }
    },
    isRiv () {
      return this.productName === 'riv'
    }
  },
  watch: {
    form: {
      // clean async error on new input
      handler () {
        if (this.canClean) {
          this.asyncError = null
        }
      },
      deep: true
    },
    loginType () {
      this.form.login = ''
    }
  },
  methods: {
    hostname () {
      return location.hostname
    },
    onSubmit () {
      this.asyncError = null
      this.loading = true
      return LoginService.mnetLogin(this.formData)
        .then(async (res) => {
          if (res.redirect_uri) {
            this.$store.commit(SET_REDIRECT_URI, res.redirect_uri)
          }
          if (this.productName !== 'mtalk-app' && this.productName !== 'riv') {
            const info = await InfoService.fetchContractInfo()
            if (!info.latest_contract) {
              this.$router.push('accept-contract')
              return
            }
          }

          if (this.productName === 'riv') {
            const userInfo = await InfoService.infoUser()
            if (!userInfo.roles?.some(role => role.type === RESELLER_ROLE_TYPE)) {
              this.asyncError = { key: 'pages.login.errorBadUserReseller' }
              throw new Error()
            }

            if (userInfo.temp_password === '1') {
              this.$store.commit(UPDATE_HAS_TEMP_PASSWORD, true)
              this.$router.push('change-password').catch(() => {})
              return
            }
          }

          return this.$store.dispatch('goToRedirectUri')
        })
        .catch((err) => {
          this.loading = false
          if (err.id === E_SERVER_HTTP && err.code === E_CODE_UNAUTHORIZED) {
            this.asyncError = { key: 'pages.login.errorLogin' }
          } else if (err.code === E_CODE_BADUSER) {
            this.productName === 'lyber'
              ? this.asyncError = { key: 'pages.login.errorBadUserLyber' }
              : this.asyncError = { key: 'pages.login.errorBadUserMnet' }
          } else {
            throw err
          }
        })
    },
    setInfoMessage () {
      this.infoMessage = { key: 'pages.login.errorBadUserLyber' }
    },
    onVisibilityChange () {
      if (this.productName !== 'mtalk-app' && !document.hidden) {
        location.reload()
      }
    }
  },
  async created () {
    if (this.$route.query.spidError) {
      logError(new Error(this.$route.query.spidError))
      this.asyncError = { key: this.$route.query.spidError }
      this.canClean = false
    } else {
      this.canClean = true
    }
    const fingerprint = await getFingerprintData()
    this.form.fingerprint = fingerprint

    document.addEventListener('visibilitychange', this.onVisibilityChange)
  },
  destroyed () {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  mounted () {
    turnOffZoom()
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  .top-right-anchor {
    position: absolute;
    top: 2px;
    right: 0;
  }
}

.separator {
  height: 8px; /* half of font size */
  border-bottom: 1px solid gray("400");
  text-align: center;
}

.separator .label {
  background-color: $white;
  padding: 0 8px;
  color: gray("500");
  position: relative;
  bottom: 4px;
}
form {
  margin-top: 30px;
}

.new-user-link {
  color: $primary;
  font-weight: 700;
  font-family: 'Source Sans Pro';
}
.recover-link {
  font-family: 'Source Sans Pro';
  font-size: 0.75rem;
  color: var(--text-color);
}
.info-msg {
  font-family: 'Source Sans Pro';
  font-size: 15px;
}
</style>
