import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import it from './it.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'it',
  messages: {
    en,
    it
  }
})
