<template>
  <div>
    <Alert :i18nData="asyncError" />
    <h4 class="mt-4 title">{{ $t('pages.recoverPass.title') }}</h4>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        class="my-4 form-group-floating"
        :description="$t('pages.recoverPass.passwordDescription')"
      >
        <b-input-group
          size="lg"
        >
          <b-input
            id="input-1"
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            required
            v-focus
            class="floating-label-input"
          />
          <label for="input-1">
            {{ $t('pages.recoverPass.passwordLabel') }}
          </label>
          <b-input-group-text slot="append">
            <span class="fa-clickable" @click.prevent="showPassword = !showPassword">
              <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />
            </span>
          </b-input-group-text>
        </b-input-group>
      </b-form-group>
      <b-button
        class="btn-centered rounded-pill"
        type="submit"
        variant="primary"
        :disabled="form.password.length < 8 || loading"
      >
        {{ $t('pages.recoverPass.buttonOk') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { E_SERVER_HTTP } from '@/constants'

export default {
  name: 'RecoverPin',
  data () {
    return {
      form: {
        password: ''
      },
      loading: false,
      asyncError: null,
      showPassword: false
    }
  },
  watch: {
    form: { // clean async error on new input
      handler () { this.asyncError = null },
      deep: true
    }
  },
  methods: {
    ...mapActions(['validationAction', 'cleanRecover']),
    onSubmit () {
      this.loading = true
      this.asyncError = null
      return this.validationAction(this.form.password).then((res) => {
        if (res.result !== 'PASSWORD UPDATED') {
          throw new Error(`Unexpected response on password update: ${res.result}`)
        }
        this.$router.push({ name: 'login' })
        this.$toasted.success(this.$t('pages.recoverPass.success'))
        return this.cleanRecover()
      }).catch(err => {
        if (err.id === E_SERVER_HTTP && err.code === 400) {
          this.asyncError = { key: 'pages.recoverPass.error' }
        } else {
          throw err
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
