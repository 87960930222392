import logger from '@/utils/logger'

// directive v-focus
export default {
  inserted: function (el) {
    const TAG_TARGETS = ['INPUT', 'BUTTON']
    if (TAG_TARGETS.includes(el.tagName)) {
      el.focus()
    } else {
      const subEl = TAG_TARGETS.map(tag => { // find first tag existing as sub element of the one using v-focus
        return el.querySelector(tag.toLowerCase())
      }).find(subEl => !!subEl)

      if (subEl) {
        logger.log(`[V-FOCUS] called on tag ${el.tagName}, focusing on child tag ${subEl.tagName}`)
        subEl.focus()
      } else {
        logger.log(`[V-FOCUS] failed to find a tag ${TAG_TARGETS} to focus`)
      }
    }
  }
}
