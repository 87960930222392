import axios from 'axios'
import {
  E_SERVER_HTTP,
  E_NETWORK
} from '@/constants'

const endpoint = axios.create({
  baseURL: '/api/v1/',
  headers: {
    Accept: 'application/json'
  }
})

endpoint.interceptors.response.use((response) => {
  const res = response.data
  if (res.error) { // transform hidden server error
    const err = new Error(res.error.message)
    err.id = E_SERVER_HTTP
    err.code = res.error.code
    throw err
  } else {
    return res
  }
}, (error) => {
  let err
  if (error.response) { // error response from server
    err = new Error(`HTTP error: ${error.response.status}`)
    err.id = E_SERVER_HTTP
    err.code = error.response.status
  } else if (error.request) { // no response received from server
    err = new Error('network error')
    err.id = E_NETWORK
  } else { // axios error setting the request
    err = new Error(error.message)
  }
  throw err
})

export default endpoint
