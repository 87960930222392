<template>
  <div>
    <Alert :i18nData="asyncError" />
    <h4 class="mt-4 title">{{ $t('pages.recoverPin.title') }}</h4>
    <div class="text-center">{{ subtitleText }}</div>
    <b-form @submit.prevent="onSubmit">
      <b-form-group class="mx-5 my-4 form-group-floating">
        <b-form-input
          id="input-1"
          class="floating-label-input"
          v-model="form.pin"
          inputmode="numeric"
          pattern="[0-9]*"
          required
          v-focus
          size="lg"
        ></b-form-input>
        <label for="input-1">
          {{ $t('pages.recoverPin.pinLabel') }}
        </label>
      </b-form-group>
      <div v-if="showSMSPinAbused"
        id ="sms-pin-abused"
        class="my-4 p-2 message-box alert alert-danger"
        v-html="$t('pages.recoverPin.smsPinAbused', { supportEmail: SUPPORT_EMAIL_ADDR }) "
      />
      <div v-else-if="showSMSPinWarning"
        id ="sms-pin-warning"
        class="my-4 p-2 message-box alert alert-warning"
        v-html="$t('pages.recoverPin.smsPinWarning', { supportEmail: SUPPORT_EMAIL_ADDR }) "
      />
      <b-button
        class="btn-centered"
        type="submit"
        variant="primary"
        :disabled="loading"
      >
        {{ $t('pages.recoverPin.buttonOk') }}
      </b-button>
    </b-form>
    <div class="d-flex justify-content-center">
      <router-link
        class="link-centered"
        :class="{ 'link-disabled': time > 0 }"
        :to="'/recover'">
        {{ resendLinkText }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { E_VALIDATION, SUPPORT_EMAIL_ADDR } from '@/constants'

export default {
  name: 'RecoverPin',
  data () {
    return {
      form: {
        pin: ''
      },
      loading: false,
      asyncError: null,
      timer: null,
      time: 0
    }
  },
  computed: {
    ...mapState({
      type: state => state.recover.type,
      value: state => state.recover.value,
      showSMSPinAbused: 'smsPinAbused'
    }),
    ...mapGetters(['showSMSPinWarning']),
    subtitleText () {
      if (this.type === 'phone') {
        return this.$t('pages.recoverPin.subtitlePhone', { value: `+${this.value}` })
      } else {
        return this.$t('pages.recoverPin.subtitleEmail', { value: this.value })
      }
    },
    resendLinkText () {
      if (this.time > 0) {
        return this.$t('pages.recoverPin.waitToResend', { seconds: this.time.toString().padStart(2, '0') })
      } else {
        return this.type === 'phone' ? this.$t('pages.recoverPin.resendPhone') : this.$t('pages.recoverPin.resendEmail')
      }
    }
  },
  watch: {
    form: { // clean async error on new input
      handler () { this.asyncError = null },
      deep: true
    }
  },
  methods: {
    ...mapActions(['checkValidation']),
    onSubmit () {
      this.loading = true
      this.asyncError = null
      return this.checkValidation(this.form.pin).then(() => {
        this.$router.push({ name: 'recoverPass' })
      }).catch(err => {
        if (err.id === E_VALIDATION) {
          this.asyncError = { key: 'pages.recoverPin.error' }
        } else {
          throw err
        }
      }).finally(() => {
        this.loading = false
      })
    },
    startTimer () {
      this.time = 59
      this.timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    }
  },
  created () {
    this.startTimer()
    this.SUPPORT_EMAIL_ADDR = SUPPORT_EMAIL_ADDR
  },
  destroyed () {
    clearInterval(this.timer)
  }
}
</script>
