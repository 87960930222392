<template>
  <component
    :is="modalType"
    :visible="visible"
    @hide="hideModal()"
    size="lg"
    :title="modalProps.title"
  >
    <component
      :is="component"
      v-bind="modalProps" />
  </component>
</template>

<script>
import Vue from 'vue'
import { HIDE_MODAL } from '@/store/mutationTypes'
import { mapState } from 'vuex'
import NoFooterModal from '@/components/modal/NoFooterModal'

export default {
  name: 'AppModal',
  components: {
    NoFooterModal
  },
  data () {
    return {
      component: null,
      modalType: 'b-modal'
    }
  },
  computed: {
    ...mapState({
      visible: 'modalVisible',
      modalComponent: 'modalComponent',
      modalProps: 'modalProps'
    })
  },
  methods: {
    hideModal () {
      this.$store.commit(HIDE_MODAL)
    }
  },
  watch: {
    modalComponent (componentName) {
      if (!componentName) return

      this.component = Vue.component(
        componentName,
        () => import(`./modal/${componentName}`)
      )
    },
    modalProps (newProps) {
      if (newProps.noFooter) {
        this.modalType = 'NoFooterModal'
      } else {
        this.modalType = 'b-modal'
      }
    }
  }
}
</script>
