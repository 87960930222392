import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/i18n'
import { getPresentableProductName } from '@/utils'
import logger from '@/utils/logger'
import ChangePassword from '../views/ChangePassword.vue'
import CompleteRegistration from '../views/CompleteRegistration.vue'
import CompleteRegistrationOk from '../views/CompleteRegistrationOk.vue'
import Login from '../views/Login.vue'
import SPIDAnag from '../views/SPIDAnag.vue'
import GoogleAuth from '../views/GoogleAuth.vue'
import SPIDAuth from '../views/SPIDAuth.vue'
import FacebookAuth from '../views/FacebookAuth.vue'
import Recover from '../views/Recover.vue'
import RecoverPin from '../views/RecoverPin.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import AcceptContract from '../views/AcceptContract.vue'
import Page404 from '../views/Page404.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/spid',
      name: 'spid',
      component: Login
    },
    {
      path: '/google-auth',
      name: 'googleAuth',
      component: GoogleAuth
    },
    {
      path: '/spid-auth',
      name: 'spidAuth',
      component: SPIDAuth
    },
    {
      path: '/spid-sso',
      name: 'spidSSO',
      component: SPIDAuth
    },
    {
      path: '/spid-anag',
      name: 'spidAnag',
      component: SPIDAnag
    },
    {
      path: '/facebook-auth',
      name: 'facebookAuth',
      component: FacebookAuth
    },
    {
      path: '/accept-contract',
      name: 'acceptContract',
      component: AcceptContract
    },
    {
      path: '/recover',
      name: 'recover',
      component: Recover
    },
    {
      path: '/recover-pin',
      name: 'recoverPin',
      component: RecoverPin,
      beforeEnter: (to, from, next) => {
        if (from.name === 'recover') {
          next()
        } else {
          next({
            name: 'recover',
            replace: true,
            query: to.query
          })
        }
      }
    },
    {
      path: '/recover-password',
      name: 'recoverPass',
      component: RecoverPassword,
      beforeEnter: (to, from, next) => {
        if (from.name === 'recoverPin') {
          next()
        } else {
          next({
            name: 'recover',
            replace: true,
            query: to.query
          })
        }
      }
    },
    {
      path: '/change-password',
      name: 'changePassword',
      component: ChangePassword
    },
    {
      path: '/complete-registration',
      name: 'completeRegistration',
      component: CompleteRegistration
    },
    {
      path: '/complete-registration-ok',
      name: 'completeRegistrationOk',
      component: CompleteRegistrationOk
    },
    {
      path: '*',
      name: 'page404',
      component: Page404
    }
  ]
})

const isFirstNav = (from) => from.name === null // fake navigation, to treat differently
const hasQueryToKeep = (to, from) => Object.keys(to.query).length === 0 && Object.keys(from.query).length !== 0

router.beforeEach((to, from, next) => { // keep query
  if (!isFirstNav(from) && hasQueryToKeep(to, from)) {
    logger.log('[ROUTER] ported query', from.query)
    next({ ...to, query: from.query })
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => { // log navigations
  if (isFirstNav(from)) {
    logger.log(`[ROUTER] starting on ${to.fullPath}`)
  } else {
    logger.log(`[ROUTER] from ${from.path} to ${to.path}`)
  }
  next()
})

router.beforeEach((to, from, next) => { // update page title
  const page = i18n.t(`titles.${to.name}`)
  const title = `${page} - ${getPresentableProductName()}`
  document.title = title
  next()
})

export default router
