<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners">
    <slot />
  </b-modal>
</template>

<script>
export default {
  inheritAttrs: false
}
</script>

<style scoped>
  :deep(.modal-footer) {
    display: none;
  }
</style>
