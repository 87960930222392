import Api from '@/services/Api'

export default {
  mnetLogin ({ login, password, fingerprint }) {
    return Api.post('mnet-login', {
      login,
      password,
      fingerprint
    })
  },
  googleLogin ({ token, fingerprint }) {
    return Api.post('google-login', { token, fingerprint })
  },
  facebookLogin ({ token }) {
    return Api.post('facebook-login', { token })
  },
  spidIdps () {
    return Api.get('spid-idps')
  }
}
