<template>
  <div class="section consent-section text-center pb-2">
    <img :src="ContractIcon" alt class="img-responsive center-block contract-image" />
    <div class="text-block">
      <h3 class="title">{{ $t('pages.acceptContract.title') }}</h3>
      <div class="article">
        {{ $t('pages.acceptContract.article') }}
        <p class="link-container">
          <a
            class="contract-modal-link"
            v-on:click="openContract"
            href="#"
          >{{ $t('pages.acceptContract.readMore') }}</a>
        </p>
      </div>
    </div>
    <div class="blocks">
      <transition name="animated"
        v-on:after-leave="changeBlock(2)" >
        <div id="first-block" class="action-block" v-if="visibleBlock == 1">
          <p
            class="statement"
          >{{ $t('pages.acceptContract.firstBlock') }}</p>
          <b-form-checkbox switch v-on:change="changeBlock(0)" class="tinDownOut my-3" />
        </div>
      </transition>
      <transition name="animated">
        <form method="POST" id="consentForm" class="action-block" v-if="visibleBlock == 2">
          <input type="hidden" name="lyber_contract" id="lyberContract" value="1" />
          <div id="second-block" class="p-3">
            <div class="statement">
              <p>{{ $t('pages.acceptContract.secondBlock.title') }}</p>
              <div class="grid">
                <div class="grid-item">Art. 2</div>
                <div class="grid-item">{{ $t('pages.acceptContract.secondBlock.articles.1') }}</div>
                <div class="grid-item">Art. 3</div>
                <div class="grid-item">{{ $t('pages.acceptContract.secondBlock.articles.2') }}</div>
                <div class="grid-item">Art. 8</div>
                <div class="grid-item">{{ $t('pages.acceptContract.secondBlock.articles.3') }}</div>
                <div class="grid-item">Art. 9</div>
                <div class="grid-item">{{ $t('pages.acceptContract.secondBlock.articles.4') }}</div>
                <div class="grid-item">Art. 11</div>
                <div class="grid-item">{{ $t('pages.acceptContract.secondBlock.articles.5') }}</div>
                <div class="grid-item">Art. 12</div>
                <div
                  class="grid-item"
                >{{ $t('pages.acceptContract.secondBlock.articles.6') }}</div>
              </div>
            </div>
            <b-form-checkbox switch v-on:change="acceptContract" class="tinDownOut my-3" />
          </div>
        </form>
      </transition>
    </div>
  </div>
</template>

<script>
import ContractIcon from '@/assets/ico-contratto@2x.png'
import InfoService from '@/services/InfoService'
import { SHOW_MODAL } from '@/store/mutationTypes'
import { mapState } from 'vuex'

export default {
  name: 'AcceptContract',
  data () {
    return {
      ContractIcon,
      visibleBlock: 0
    }
  },
  computed: {
    ...mapState(['redirectUri'])
  },
  methods: {
    changeBlock (num) {
      setTimeout(() => {
        this.visibleBlock = num
      }, 100)
    },
    acceptContract () {
      this.changeBlock(0)
      InfoService.setContractInfo(1, 1).then(() => {
        if (this.redirectUri) {
          window.location.href = this.redirectUri
        }
      }).catch(err => {
        this.changeBlock(2)
        throw err
      })
    },
    openContract () {
      this.$store.commit(SHOW_MODAL, {
        componentName: 'IFrameModal',
        props: {
          src: 'https://messagenet.com/it/legal/contratto_embed.html',
          title: this.$t('titles.acceptContract'),
          noFooter: true
        }
      })
    }
  },
  created () {
    this.changeBlock(1)
  }
}
</script>

<style scoped>

:deep(.custom-switch .custom-control-input:checked ~ .custom-control-label::after) {
  -webkit-transform: translateX(1.75rem);
  transform: translateX(1.75rem);
}

:deep(.custom-switch .custom-control-label::before) {
  border-radius: 1.5rem;
  width: 3.75rem;
  left: -3.25rem;
}

:deep(.custom-switch .custom-control-label::after) {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: 1.5rem;
  left: calc(-3.25rem + 2px);
}

:deep(.custom-control-label::before) {
  height: 2rem;
}
</style>

<style lang="scss" scoped>
.contract-image {
  height: 134px;
  margin: 20px auto;
}

.consent-section {
  color: #434343;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 0 10px;
  overflow-x: hidden;
  min-height: 685px;
}

.consent-section .title {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
}

.consent-section .link-container {
  margin: 20px auto;
}

.consent-section .link-container a {
  color: #434343;
  text-decoration: underline;
}

.consent-section .action-block {
  padding: 30px;
  font-size: 15px;
  box-shadow: 0 0 2px 2px #eee;
  border-radius: 2px;
  transition: transform .5s;
}

.consent-section .action-block .details {
  font-size: 12px;
}

.toggle.round,
.toggle-on.round,
.toggle-off.round {
  border-radius: 20px;
}

.toggle.round .toggle-handle {
  border-radius: 20px;
}

.consent-section .toggle {
  margin: 20px auto;
}

#consentForm {
  padding: 0;
}

#consentForm.animated-in {
  padding: 30px;
}

.consent-section .action-block ul,
.consent-section .action-block li {
  list-style-type: none;
}

.consent-section .action-block li {
  display: flex;
  justify-content: flex-start;
}

.consent-section .action-block .article-number {
  width: 80px;
}

@-webkit-keyframes bouncein {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bouncein {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bounceout {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes bounceout {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animated-enter {
  transform: translateX(100%);
}

.animated-leave-to {
  transform: translateX(-100%);
}

.statement {
  font-size: 15px;
}

.grid {
  font-size: 12px;
  display: grid;
  grid-template-columns: 45px auto;
}

.grid-item {
  text-align: left;
}

.tinDownOut {
  -webkit-animation-name: tinDownOut;
  animation-name: tinDownOut;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation-delay: 1s;
}

@-webkit-keyframes tinDownOut {
  0%,
  20%,
  40%,
  50% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10%,
  30% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes tinDownOut {
  0%,
  20%,
  40%,
  50% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  10%,
  30% {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
</style>
