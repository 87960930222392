<template>
  <b-button :class="`btn-brand-${brand}`" :to="to" :disabled="disabled" @click="$emit('click')">
    <b-img :src="getImgUrl" width="25" height="25" :alt="`${brand} logo`"/>
    <span class="btn-text">
      {{ $t('pages.login.buttonBrand', { brand: capitalize(brand) }) }}
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonBrand',
  props: {
    brand: {
      String,
      required: true
    },
    to: {
      String,
      required: false
    },
    disabled: {
      Boolean,
      default: false
    }
  },
  computed: {
    getImgUrl () {
      return require(`@/assets/brands/${this.brand}.png`)
    }
  },
  methods: {
    capitalize (brand) {
      return brand.charAt(0).toUpperCase() + brand.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
$brand-colors: (
  facebook: #3b5998,
  google: $white
);

@each $brand, $value in $brand-colors {
  .btn-brand-#{$brand} {
    @include button-variant($value, $value);
    display: flex;
    align-items: center;
    width: 270px;
    padding: 0.65rem;
    margin: 0.4rem auto;
    text-align: start;
    border-color: gray("400");
  }
}

.btn-text {
  padding-left: 24px;
}

.brand-name {
  text-transform: capitalize;
}
</style>
