import Vue from 'vue'
import '@/vendor'
import App from './App.vue'
import router from './router'
import store from './store/index'
import i18n from './i18n'
import Alert from '@/elements/Alert'
import Spinner from '@/elements/TheSpinner'
import ProductLogo from '@/elements/ProductLogo'
import focus from '@/directives/focus'
import { handleAndLogError } from '@/utils/error'

Vue.component('Alert', Alert)
Vue.component('Spinner', Spinner)
Vue.component('ProductLogo', ProductLogo)
Vue.directive('focus', focus)

Vue.config.productionTip = false
Vue.config.errorHandler = handleAndLogError

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
