<template>
  <div class="switch d-flex justify-content-center align-items-center"
    :class="value === optionsValues[0] ? 'left' : 'right'">
    <label @click="switchLeft">
      {{ optionsText[0] }}
    </label>
    <div class="toggle-outside" @click="change">
      <div class="toggle-inside"></div>
    </div>
    <label @click="switchRight">
      {{ optionsText[1] }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    name: {
      type: String,
      required: true
    },
    optionsText: {
      type: Array,
      required: true
    },
    optionsValues: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    switchLeft () {
      const newVal = this.optionsValues[0]
      if (this.value === this.optionsValues[1]) { this.$emit('input', newVal) }
    },
    switchRight () {
      const newVal = this.optionsValues[1]
      if (this.value === this.optionsValues[0]) { this.$emit('input', newVal) }
    },
    change () {
      let valueToEmit

      if (this.value === this.optionsValues[0]) {
        valueToEmit = this.optionsValues[1]
      } else {
        valueToEmit = this.optionsValues[0]
      }
      this.$emit('input', valueToEmit)
    }
  }
}
</script>

<style lang="scss" scoped>

$base: 2rem;
$pad: 2px;

label {
  margin-bottom: 0;
  opacity: .8;
  flex: 1;
  cursor: pointer;
}

label:first-of-type {
  text-align: right;
}

label:last-of-type {
  text-align: left;
}

.toggle-outside {
  width: 2 * $base;
  height: $base;
  background-color: $primary;
  position: relative;
  margin: 0 .5rem;
  border-radius: $base;
  cursor: pointer;
  .toggle-inside {
    background-color: $white;
    border-radius: $base;
    width: calc(#{$base} - 2 * #{$pad});
    height: calc(#{$base} - 2 * #{$pad});
    position: absolute;
    top: $pad;
    left: $pad;
    box-shadow: 0px 3px 2px 1px rgba(0,0,0,0.1);
    transition: left .25s;
  }
}

.left label:first-of-type{
  color: $primary;
  opacity: 1;
}

.right {
  label:last-of-type {
    color: $primary;
    opacity: 1;
  }
  .toggle-inside {
    left: calc(#{$base} + #{$pad});
  }
}
</style>
