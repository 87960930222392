<template>
  <div>
    <div v-if="!prefixChoiceOpen">
      <Alert :i18nData="asyncError" />
      <div v-if="!loading & showForm">
        <h4 class="mt-4 title">{{ $t('pages.completeRegistration.title') }}</h4>
        <p class="instructions my-4" v-html="$t('pages.completeRegistration.instructionsHtml')">
        </p>
        <b-form @submit.prevent="onSubmit">
          <b-form-group class="form-group-floating">
            <b-input-group size="lg">
              <b-input-group-text slot="prepend" class="bg-white">
                <span class="fa-clickable" @click="prefixChoiceOpen = true">
                  <b-img :src="`/img/flags/${currentCountryPrefix.code}@1x.png`" width="28" height="20"></b-img>
                  <span class="ml-2 text-muted">+{{ currentCountryPrefix.prefix }}</span>
                </span>
              </b-input-group-text>
              <b-form-input
                id="input-1"
                key="1"
                v-model="form.phone"
                type="tel"
                name="tel"
                :placeholder="$t('pages.completeRegistration.mobileLabel')"
                required
                v-focus
                class="floating-label-input"
              />
              <label for="input-1" class="tel-label">
                {{ $t('pages.completeRegistration.mobileLabel') }}
              </label>
            </b-input-group>
          </b-form-group>
          <b-button
            class="btn-centered mt-5"
            type="submit"
            variant="primary"
            :disabled="loading">
            {{ $t('pages.completeRegistration.buttonOk') }}
          </b-button>
        </b-form>
      </div>
      <div class="text-center mt-5">
        <a class="primary" href="mailto:support@messagenet.it">{{ $t('pages.completeRegistration.supportMessage') }}</a>
      </div>
    </div>
    <div v-else>
      <PrefixChoice :currentCountryPrefix="currentCountryPrefix" @chooseCountryPrefix="chooseCountryPrefix" />
    </div>
    <Spinner :status="loading" />
  </div>
</template>

<script>
import { getValueByName } from '@/utils/url'
import AuthService from '@/services/AuthService'
import InfoService from '@/services/InfoService'
import PrefixChoice from '@/components/PrefixChoice'

export default {
  name: 'CompleteRegistration',
  components: {
    PrefixChoice
  },
  data () {
    return {
      form: {
        guid: '',
        email: '',
        code: '',
        phone: ''
      },
      currentCountryPrefix: {
        code: 'IT',
        prefix: '39',
        name: 'Italia'
      },
      validated: false,
      logged: false,
      loading: true,
      showForm: false,
      prefixChoiceOpen: false,
      asyncError: null
    }
  },
  computed: {
    completePhone () {
      return this.currentCountryPrefix.prefix + this.form.phone
    }
  },
  watch: {
    form: { // clean async error on new input
      handler () { this.asyncError = null },
      deep: true
    }
  },
  methods: {
    onSubmit () {
      this.loading = true
      this.asyncError = null

      return this.performValidation()
    },
    checkValidation () {
      return AuthService.checkValidation({
        type: 'email',
        value: this.form.email,
        guid: this.form.guid,
        code: this.form.code
      }).then(res => {
        if (res.validated === 'ALREADY') {
          this.asyncError = { key: 'pages.completeRegistration.sessionError' }
          throw new Error()
        } else {
          this.validated = true
        }
      })
    },
    performValidation () {
      return Promise.resolve().then(() => {
        if (!this.validated) {
          return this.checkValidation()
        } else {
          return Promise.resolve()
        }
      }).then(() => {
        if (this.logged) {
          return this.loggedValidationAction()
        } else {
          return this.anonymousValidationAction()
        }
      }).catch(() => {
        if (this.asyncError === null) {
          this.asyncError = { key: 'errors.unexpected' }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    anonymousValidationAction () {
      const params = {
        guid: this.form.guid,
        phone: this.completePhone
      }
      return AuthService.validationAction(params).then((res) => {
        if (res.email === 'VALIDATED') {
          return this.registrationCompleted()
        } else {
          if (res.email === 'NOT VALIDATED' && res.details.match(/bad phone/i)) {
            this.asyncError = { key: 'pages.completeRegistration.badPhoneError' }
          }
          throw new Error()
        }
      })
    },
    loggedValidationAction () {
      return AuthService.validationAction({ guid: this.form.guid }).then((vaRes) => {
        if (vaRes.email === 'NOT VALIDATED' && vaRes.details.match(/user mismatch/i)) {
          this.showForm = true
          this.logged = false
        } else if (vaRes.email === 'VALIDATED') {
          return this.registrationCompleted()
        } else {
          throw new Error()
        }
      })
    },
    chooseCountryPrefix (countryPrefix) {
      this.prefixChoiceOpen = false
      this.currentCountryPrefix = countryPrefix
    },
    registrationCompleted () {
      this.$router.push({ name: 'completeRegistrationOk' })
    }
  },
  created () {
    this.form.guid = getValueByName('guid')
    this.form.email = getValueByName('email')
    this.form.code = getValueByName('code')
  },
  mounted () {
    InfoService.infoMnet().then(() => true).catch(() => false).then((logged) => {
      this.logged = logged
      if (this.logged) {
        return this.performValidation()
      } else {
        this.showForm = true
      }
    }).catch((error = { key: 'errors.unexpected' }) => {
      this.asyncError = error
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss" scoped>
.instructions {
  font-size: 14px;
  text-align: center;
}
</style>
