// error ids
export const E_SERVER_HTTP = 'SERVER_HTTP' // server response outside of 2xx range
export const E_NETWORK = 'NETWORK' // no server response received, usually because of connection problems on client side
export const E_VALIDATION = 'E_VALIDATION' // when user input didn't pass server side validation
export const E_CODE_UNAUTHORIZED = 401
export const E_CODE_BADUSER = 400

// Google
export const GOOGLE_OAUTH_BASE = 'https://accounts.google.com/o/oauth2/v2/auth'
export const GOOGLE_EMAIL_SCOPE = 'https://www.googleapis.com/auth/userinfo.email'
export const GOOGLE_PROFILE_SCOPE = 'https://www.googleapis.com/auth/userinfo.profile'
export const GOOGLE_AUTH_SCOPE = `openid ${GOOGLE_EMAIL_SCOPE} ${GOOGLE_PROFILE_SCOPE}`
export const GOOGLE_TOKEN_RESPONSE_TYPE = 'token'

export const PRESENTABLE_PRODUCT_NAME = {
  mnet: 'MessageNet',
  lyber: 'Lyber',
  'mtalk-app': 'Mtalk'
}

export const PRESENTABLE_RIV_NAME = {
  default: 'Rivendita VoIP',
  wildix: 'Wildix - Rivendita VoIP',
  kpn: 'KPNQwest Italia',
  altitud: 'trafficovoce.it',
  itvoip: 'Rivendita IT-VOIP',
  globalnet: 'GlobalNet - Wholesale VoIP'
}

export const LYBER_FORGOTTEN_PWD_CONTEXT = 'lyber.forgotten-pwd.web'
export const SITE_FORGOTTEN_PWD_CONTEXT = 'site.forgotten-pwd.web'

export const SMS_PIN_WARNING_THRESHOLD = 3
export const TYPE_PHONE = 'phone'
export const TYPE_EMAIL = 'email'

export const SUPPORT_EMAIL_ADDR = 'mailto:support@messagenet.it'

export const VALIDATION_STATUS_OK = 'OK'
export const VALIDATION_STATUS_KO = 'KO'
export const VALIDATION_STATUS_ABUSED = 'ABUSED'
export const VALIDATION_STATUS_EXISTS = 'EXISTS'

export const RESELLER_ROLE_TYPE = 'reseller'
