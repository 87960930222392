import { getProductName, getReseller } from '@/utils/url'
import { PRESENTABLE_PRODUCT_NAME, PRESENTABLE_RIV_NAME } from '@/constants'

export function matchString (search, candidates) {
  if (!Array.isArray(candidates)) {
    candidates = [candidates]
  }
  if (typeof search !== 'string') return true
  return candidates.reduce((acc, el) => {
    if (typeof el !== 'string') return acc || false
    return acc || el.toLowerCase().includes(search.toLowerCase())
  }, false)
}

export function alphaCompare (s1, s2) {
  if (typeof s1 !== 'string') return 1
  if (typeof s2 !== 'string') return -1
  return s1.localeCompare(s2)
}

export function getPresentableProductName () {
  const product = getProductName()
  if (product === 'riv') {
    const reseller = getReseller()
    return PRESENTABLE_RIV_NAME[reseller] || 'Rivendita VoIP'
  }
  return PRESENTABLE_PRODUCT_NAME[product] || 'MessageNet'
}

export function turnOffZoom () {
  const product = getProductName()
  if (/app/.test(product)) {
    document.addEventListener('touchmove', function (event) {
      if (event.scale !== 1) { event.preventDefault() }
    }, { passive: false })
  }
}
