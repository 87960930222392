import Api from '@/services/Api'

export default {
  changePassword ({ oldPassword, newPassword }) {
    return Api.post('/mnet/change-password', {
      'old-password': oldPassword,
      password: newPassword
    }).then(res => {
      if (res.error) {
        throw res.error
      } else {
        return res
      }
    })
  }
}
