<template>
  <div>
    <Alert :i18nData='asyncError' />
    <h5 class='my-4'>{{ $t('pages.spidAnag.title') }}</h5>
    <Spinner :status='loading' />
    <div v-show='!loading'>
      <b-form method='POST' action='/spid-anag' @submit='onSubmit'>
        <b-form-group
          v-for='(item, index) in form'
          :key='index'
          :label-for='`input-x-${index}`'
          label-class='d-flex justify-content-between align-items-end'
        >
          <template slot='label' v-if='item.mode !== 2'>
            <div>
              {{ item.label }}
            </div>
          </template>
          <div v-if='(item.mode === 1 && item.do) || item.mode === 0'>
            <b-form-input
              :id='item.key'
              v-model.trim='item.val'
              type='text'
              :name='item.key'
              :required='item.required'
              size='lg'
            >
            </b-form-input>
          </div>
          <div v-if='item.mode === 1 && !item.do'>
            <div>
              {{ item.val }}
            </div>
          </div>
          <div v-if='item.mode === 2 || (item.mode === 1 && !item.do)'>
            <input
              type='hidden'
              :id='item.key'
              :value='item.val'
              :name='item.key'
            />
          </div>
        </b-form-group>
        <b-button
          class='btn-centered'
          type='submit'
          variant='primary'
          :disabled='loading'
        >
          {{ $t('pages.spidAnag.buttonOk') }}
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      form: [
        {
          key: 'first_name',
          mode: 1,
          label: 'Nome',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'last_name',
          mode: 1,
          label: 'Cognome',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'business_name',
          mode: 1,
          label: 'Ragione Sociale',
          required: false,
          val: '',
          do: false
        },
        {
          key: 'postcode',
          mode: 0,
          label: 'CAP',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'address',
          mode: 0,
          label: 'Indirizzo',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'comune',
          mode: 0,
          label: 'Comune',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'provincia',
          mode: 0,
          label: 'Provincia',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'cf',
          mode: 1,
          label: 'Codice Fiscale',
          required: false,
          val: '',
          do: false
        },
        {
          key: 'pi',
          mode: 1,
          label: 'Partita IVA',
          required: false,
          val: '',
          do: false
        },
        {
          key: 'email',
          mode: 1,
          label: 'Email',
          required: true,
          val: '',
          do: false
        },
        {
          key: 'phone',
          mode: 1,
          label: 'Cellulare',
          required: true,
          val: '',
          do: false
        },
        { key: '__mandatory_consent', mode: 2, val: '', do: false },
        { key: '__reconciliate', mode: 2, val: '', do: false },
        { key: 'account_type', mode: 2, val: '', do: false },
        { key: 'country', mode: 2, val: '', do: false },
        { key: 'currency', mode: 2, val: '', do: false },
        { key: 'lang', mode: 2, val: '', do: false },
        { key: 'originating_ip', mode: 2, val: '', do: false },
        { key: 'unfair_terms', mode: 2, val: '', do: false }
      ],
      loading: false,
      asyncError: null,
      showSpinner: true
    }
  },
  methods: {
    onSubmit (event) {
      const cf = this.form.find((item) => item.key === 'cf' && item.val !== '')
      const pi = this.form.find((item) => item.key === 'pi' && item.val !== '')
      if (!cf && !pi) {
        this.asyncError = 'pages.spidAnag.errorPiCf'
        event.preventDefault()
      } else {
        this.asyncError = null
        this.loading = true
      }
    }
  },
  mounted () {
    this.form.forEach((item) => {
      item.val = this.$route.query[item.key]
      item.do = item.val === ''
    })
  }
}
</script>

<style lang='scss' scoped>
.form-group {
  position: relative;

  .top-right-anchor {
    position: absolute;
    top: 2px;
    right: 0;
  }
}

.separator {
  height: 8px; /* half of font size */
  border-bottom: 1px solid gray('400');
  text-align: center;
}

.separator .label {
  background-color: $white;
  padding: 0 8px;
  color: gray('500');
  position: relative;
  bottom: 4px;
}
form {
  margin-top: 30px;
}
</style>
