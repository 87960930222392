<template>
  <div>
    <h4 class="mt-4 title">{{ hasTempPassword ? $t('pages.changePassword.tempPassTitle') : $t('pages.changePassword.title') }}</h4>
    <Alert :i18nData="asyncError" />
    <Spinner :status="loading" />
    <p v-if="hasTempPassword" v-html="$t('pages.changePassword.tempPassText')" class="my-4 text-center" />
    <b-form @submit.prevent="onSubmit" v-if="!loading">
      <b-form-group class="my-4 form-group-floating">
        <b-input-group size="lg">
          <b-form-input
            id="old-password"
            v-model="form.oldPassword"
            :type="showOldPassword ? 'text' : 'password'"
            autocomplete="password"
            required
            v-focus
            class="floating-label-input"
          />
          <label for="old-password">
            {{ $t('pages.changePassword.oldPasswordLabel') }}
          </label>
          <b-input-group-text slot="append">
            <span class="fa-clickable" @click.prevent="showOldPassword = !showOldPassword">
              <font-awesome-icon :icon="showOldPassword ? 'eye-slash' : 'eye'" />
            </span>
          </b-input-group-text>
        </b-input-group>
      </b-form-group>
      <b-form-group
        class="my-4 form-group-floating"
        :description="$t('pages.changePassword.passwordDescription')">
        <b-input-group
          size="lg">
          <b-form-input
            id="new-password"
            v-model="form.newPassword"
            :type="showNewPassword ? 'text' : 'password'"
            autocomplete="password"
            required
            class="floating-label-input"
          />
           <label for="new-password">
            {{ $t('pages.changePassword.newPasswordLabel') }}
          </label>
          <b-input-group-text slot="append">
            <span class="fa-clickable" @click.prevent="showNewPassword = !showNewPassword">
              <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" />
            </span>
          </b-input-group-text>
        </b-input-group>
      </b-form-group>

      <b-button
        class="btn-centered rounded-pill submit-btn"
        type="submit"
        variant="primary"
        :disabled="form.newPassword.length < 8 || loading"
      >
        {{ $t('pages.changePassword.buttonOk') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import PasswordService from '@/services/PasswordService'
import { getRedirectUri, getValueByName } from '@/utils/url'
import { turnOffZoom } from '@/utils'
import { mapState } from 'vuex'
import store from '@/store'
import { UPDATE_HAS_TEMP_PASSWORD } from '@/store/mutationTypes'

export default {
  name: 'ChangePassword',
  data () {
    return {
      form: {
        oldPassword: '',
        newPassword: ''
      },
      loading: false,
      asyncError: null,
      showOldPassword: false,
      showNewPassword: false
    }
  },
  computed: {
    ...mapState([
      'hasTempPassword'
    ])
  },
  watch: {
    form: { // clean async error on new input
      handler () { this.asyncError = null },
      deep: true
    }
  },
  methods: {
    onSubmit () {
      this.loading = true
      this.asyncError = null
      return PasswordService.changePassword(this.form).then(() => {
        this.$toasted.success(this.$t('pages.changePassword.success'))
        setTimeout(() => {
          window.location.href = getRedirectUri()
        }, 1500)
      }).catch(err => {
        this.loading = false
        if (err.code === 403) {
          this.asyncError = { key: 'pages.changePassword.oldPasswordError' }
        } else if (err.code === 400 && err.message.match(/too short/)) {
          this.asyncError = { key: 'pages.changePassword.passwordShortError' }
        } else if (err.code === 400 && err.message.match(/too long/)) {
          this.asyncError = { key: 'pages.changePassword.passwordLongError' }
        } else {
          throw err
        }
      })
    }
  },
  mounted () {
    turnOffZoom()
  },
  created () {
    const tempPassword = getValueByName('temp_password')
    if (tempPassword && tempPassword === '1') {
      store.commit(UPDATE_HAS_TEMP_PASSWORD, true)
    }
  }
}
</script>
