<template>
  <div v-if="isRiv" class="logo-container w-100">
    <div id="reseller-icon" class="mx-auto"></div>
  </div>
  <b-img v-else-if="logoSrc" :src="logoSrc" :center="true" alt="logo" :width="logoWidth" />
</template>

<script>
import { getProductName } from '@/utils/url'

export default {
  name: 'ProductLogo',
  computed: {
    productName () {
      return getProductName()
    },
    logoSrc () {
      if (this.productName === 'mtalk-app') {
        return null
      } else {
        const logo = this.productName === 'lyber' ? this.productName : 'mnet'
        return require(`@/assets/logos/${logo}.png`)
      }
    },
    logoWidth () {
      return this.productName === 'lyber' ? '110px' : '180px'
    },
    isRiv () {
      return this.productName === 'riv'
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: $logo-max-width;
  padding: 2*$spacer 0 0 0;
  margin-bottom: 1.5rem;
}
</style>
