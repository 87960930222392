<template>
  <TransitionExpand>
    <div v-if="i18nData" class="wrapper my-3">
      <div role="alert" class="alert alert-danger with-icon mb-0">
        <font-awesome-icon icon="exclamation-circle" size="2x" class="danger-color" />
        <div class="ml-3">{{ $t(i18nData.key, i18nData.values) }}</div>
      </div>
    </div>
  </TransitionExpand>
</template>

<script>
import TransitionExpand from '@/elements/TransitionExpand'

export default {
  name: 'Alert',
  props: {
    i18nData: {
      type: Object
    }
  },
  components: {
    TransitionExpand
  }
}
</script>

<style lang="scss" scoped>
.with-icon {
  display: flex;
  align-items: center;
}
.danger-color {
  color: $danger;
}
</style>
