<template>
  <div>
    <div class="d-flex flex-column justify-content-center">
        <Toggle
          id="user-type-toggle"
          name="user-type"
          :optionsText="[$t('pages.login.spidPrivate'), $t('pages.login.spidBusiness')]"
          :optionsValues="typeOptions"
          v-model="spidType"
        />
        <hr>
        <div class="d-flex justify-content-center">
            <!-- AGID - SPID IDP BUTTON LARGE "ENTRA CON SPID" * begin * -->
            <a href="#" class="italia-it-button italia-it-button-size-l button-spid" spid-idp-button="#spid-idp-button-large-get" aria-haspopup="true" aria-expanded="false">
                <span class="italia-it-button-icon"><img src="spid/production/img/spid-ico-circle-bb.svg" onerror="this.src='img/spid-ico-circle-bb.png'; this.onerror=null;" alt="" /></span>
                <span class="italia-it-button-text">Entra con SPID</span>
            </a>
            <div id="spid-idp-button-large-get" class="spid-idp-button spid-idp-button-tip spid-idp-button-relative">
                <ul id="spid-idp-list-large-root-get" class="spid-idp-button-menu" aria-labelledby="spid-idp">
                    <li class="spid-idp-button-link" v-for="(item, index) in idpList" :key="index" :data-idp="item.id">
                        <router-link :to="routerLink(item)" :style="`background-color:${item.background}`">
                            <span class="spid-sr-only">{{ item.name }}</span>
                            <img :src="getImg(item, 'svg')" :onerror="`this.src='${getImg(item, 'png' )}'; this.onerror=null;`" :alt="item.name" />
                        </router-link>
                    </li>
                </ul>
                <!-- <ul id="spid-idp-list-large-root-get" class="spid-idp-button-menu" aria-labelledby="spid-idp">
                    <li class="spid-idp-button-link" data-idp="arubaid">
                        <a href="#"><span class="spid-sr-only">Aruba ID</span><img src="spid/production/img/spid-idp-arubaid.svg" onerror="this.src='spid/production/img/spid-idp-arubaid.png'; this.onerror=null;" alt="Aruba ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="infocertid">
                        <a href="#"><span class="spid-sr-only">Infocert ID</span><img src="spid/production/img/spid-idp-infocertid.svg" onerror="this.src='spid/production/img/spid-idp-infocertid.png'; this.onerror=null;" alt="Infocert ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="intesaid">
                        <a href="#"><span class="spid-sr-only">Intesa ID</span><img src="spid/production/img/spid-idp-intesaid.svg" onerror="this.src='spid/production/img/spid-idp-intesaid.png'; this.onerror=null;" alt="Intesa ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="lepidaid">
                        <a href="#"><span class="spid-sr-only">Lepida ID</span><img src="spid/production/img/spid-idp-lepidaid.svg" onerror="this.src='spid/production/img/spid-idp-lepidaid.png'; this.onerror=null;" alt="Lepida ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="namirialid">
                        <a href="#"><span class="spid-sr-only">Namirial ID</span><img src="spid/production/img/spid-idp-namirialid.svg" onerror="this.src='spid/production/img/spid-idp-namirialid.png'; this.onerror=null;" alt="Namirial ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="posteid">
                        <a href="#"><span class="spid-sr-only">Poste ID</span><img src="spid/production/img/spid-idp-posteid.svg" onerror="this.src='spid/production/img/spid-idp-posteid.png'; this.onerror=null;" alt="Poste ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="sielteid">
                        <a href="#"><span class="spid-sr-only">Sielte ID</span><img src="spid/production/img/spid-idp-sielteid.svg" onerror="this.src='ispid/production/mg/spid-idp-sielteid.png'; this.onerror=null;" alt="Sielte ID" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="spiditalia">
                        <a href="#"><span class="spid-sr-only">SPIDItalia Register.it</span><img src="spid/production/img/spid-idp-spiditalia.svg" onerror="this.src='ispid/production/mg/spid-idp-spiditalia.png'; this.onerror=null;" alt="SpidItalia" /></a>
                    </li>
                    <li class="spid-idp-button-link" data-idp="timid">
                        <a href="#"><span class="spid-sr-only">Tim ID</span><img src="spid/production/img/spid-idp-timid.svg" onerror="this.src='spid/production/img/spid-idp-timid.png'; this.onerror=null;" alt="Tim ID" /></a>
                    </li> -->
                    <!-- <li class="spid-idp-support-link" data-spidlink="info">
                      <a href="https://www.spid.gov.it">Maggiori informazioni</a>
                    </li>
                    <li class="spid-idp-support-link" data-spidlink="rich">
                        <a href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a>
                    </li>
                    <li class="spid-idp-support-link" data-spidlink="help">
                        <a href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a>
                    </li> -->
                  <!-- </ul> -->
            </div>
            <!-- AGID - SPID IDP BUTTON LARGE "ENTRA CON SPID" * end * -->
        </div>
    </div>
    <div style="font-family: Verdana, sans-serif;font-size: 12px;" class="mt-4 text-justify">
        SPID &egrave; il sistema di accesso che consente di utilizzare, con un'identit&agrave; digitale unica, i servizi online della Pubblica Amministrazione e dei privati accreditati. Se sei gi&agrave; in possesso di un'identità digitale, accedi con le credenziali
        del tuo gestore. Se non hai ancora un'identit&agrave; digitale, richiedila ad uno dei gestori.
    </div>
    <div class="mt-2">
        <a class="loginpin_link" href="http://www.spid.gov.it" target="_blank">Maggiori informazioni su SPID</a>
    </div>
    <div>
        <a class="loginpin_link" href="http://www.spid.gov.it/richiedi-spid" target="_blank">Non hai SPID?</a>
    </div>
    <div class="d-flex justify-content-center mt-3 pt-1 pb-1 pl-5 pr-5">
        <img class="img-fluid" src="spid/production/img/spid-agid-logo-lb.png">
    </div>
  </div>
</template>

<script>
import LoginService from '@/services/LoginService'
import Toggle from '@/elements/Toggle'
export default {
  name: 'ButtonSPID',
  components: {
    Toggle
  },
  props: {
    to: {
      String,
      required: false
    },
    disabled: {
      Boolean,
      default: false
    }
  },
  data () {
    return {
      idpList: [],
      typeOptions: ['private', 'business'],
      spidType: 'private'
    }
  },
  computed: {
    getImgUrl () {
      return require(`@/assets/brands/${this.brand}.png`)
    }
  },
  methods: {
    routerLink (item) {
      const query = {
        idp: encodeURIComponent(item.id)
      }
      if (this.spidType === 'business') {
        query.business = 'PX'
      }
      return {
        path: '/spid-auth',
        query
      }
    },
    getImg (item, ext) {
      return item.path ? item.path : `${this.default_path}/img/spid-idp-${item.id}.${ext}`
    },
    capitalize (brand) {
      return brand.charAt(0).toUpperCase() + brand.slice(1)
    },
    loadScript (id, src, onload) {
      if (!document.getElementById(id)) {
        const script = document.createElement('script')
        script.setAttribute('src', src)
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('id', id)
        if (onload) {
          script.onload = onload
        }
        document.head.appendChild(script)
      }
    },
    loadIdps () {
      return LoginService.spidIdps({})
        .then(async (res) => {
          const array = this.idp_map.filter(item => {
            if (item.id in res) {
              item.idp = res[item.id]
              return true
            } else {
              return false
            }
          })
          for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1))
            const t = array[i]
            array[i] = array[j]
            array[j] = t
          }
          this.idpList = array
      })
    }
  },
  created () {
    this.default_path = 'spid/production'
    this.idp_map = [
      { label: 'Aruba ID', id: 'arubaid' },
      { label: 'Infocert ID', id: 'infocertid' },
      { label: 'Intesa ID', id: 'intesaid' },
      { label: 'Lepida ID', id: 'lepidaid' },
      { label: 'Namirial ID', id: 'namirialid' },
      { label: 'Poste ID', id: 'posteid' },
      { label: 'SPIDItalia Register.it', id: 'spiditalia' },
      { label: 'Sielte ID', id: 'sielteid' },
      { label: 'Tim ID', id: 'timid' },
      { label: 'Teamsystem ID', id: 'teamsystemid' },
      { label: 'Etna ID', id: 'etnaid' },
      { label: 'InfoCamere ID', id: 'infocamereid' },
      { label: 'Intesi Group', id: 'intesigroupspid' },
      {
        label: 'SPID Validator',
        id: 'validator',
        path: 'spid/spid-validator/img/logo.png',
        background: '#007db3'
      },
      {
        label: 'SPID Test',
        id: 'testenv2',
        path: 'spid/spid-testenv2/img/spid-logo.svg',
        background: '#007db3'
      },
      {
        label: 'SPID Demo',
        id: 'demo',
        path: 'spid/spid-testenv2/img/spid-logo.svg',
        background: '#007db3'
      },
      {
        label: 'Validator',
        id: 'validator.spid.gov.it',
        path: 'spid/spid-validator/img/logo.png',
        background: '#007db3'
      }
    ]
    this.info = [
      { link: 'https://www.spid.gov.it', title: 'Maggiori informazioni' },
      { link: 'https://www.spid.gov.it/richiedi-spid', title: 'Non hai SPID?' },
      { link: 'https://www.spid.gov.it/serve-aiuto', title: 'Serve aiuto?' }
    ]

    // const self = this
    this.loadScript('jquery', 'spid/production/js/jquery.min.js', () => {
      this.loadScript(
        'access-button',
        'spid/production/js/spid-sp-access-button.min.js',
        () => this.loadIdps()
      )
    })
  }
}
</script>

<style lang="scss" scoped>
.btn-brand-spid {
  @include button-variant(#007db3, #007db3);
  display: flex;
  align-items: center;
  width: 270px;
  padding: 0.65rem;
  margin: 0.4rem auto;
  text-align: start;
  border-color: gray("400");
}

.btn-text {
  padding-left: 24px;
}

.brand-name {
  text-transform: capitalize;
}

img {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

@import "../../public/spid/production/css/spid-sp-access-button.min.css";
</style>
