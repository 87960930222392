export function getValueByName (name) {
  let value = ''
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has(name)) {
    value = urlParams.get(name)
  }
  return value
}

export function getProductName () { // lyber, mnet, mtalk-app
  return getValueByName('product_name') || 'mnet'
}

export function getRedirectUri () {
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('redirect_uri')) {
    return decodeURIComponent(urlParams.get('redirect_uri'))
  } else if (urlParams.has('redirect_after_login')) {
    return decodeURIComponent(urlParams.get('redirect_after_login'))
  } else {
    const productName = getProductName()
    if (productName === 'lyber') {
      return process.env.VUE_APP_HOME_LYBER
    } else {
      return process.env.VUE_APP_HOME_MNET
    }
  }
}

export function getReseller () {
  return getValueByName('rt') || 'default'
}

export function buildUrlFromParams (base, params) {
  const queryParams = []
  for (const key in params) {
    queryParams.push(`${key}=${encodeURIComponent(params[key])}`)
  }

  return [base, queryParams.join('&')].join('?')
}

export function buildSmartProvisioningUrl () {
  const productName = getProductName()
  const params = {
    type: productName
  }
  return buildUrlFromParams(process.env.VUE_APP_NEW_USER_SMART, params)
}
