<template>
  <div id="app">
    <div class="background">
      <main class="main">
        <ProductLogo />
        <div class="main-container">
          <router-view :key="$route.path" />
        </div>
      </main>
      <footer class="footer pb-2" v-if="!hideFooter">
        <div class="d-flex align-items-center justify-content-center">
          <span class="copy px-2">&copy;2024 MessageNet S.p.A.</span>
        </div>
      </footer>
      <AppModal />
    </div>
  </div>
</template>

<script>
import { getProductName, getReseller } from '@/utils/url'
import AppModal from '@/components/AppModal'

export default {
  name: 'App',
  components: {
    AppModal
  },
  computed: {
    productName () {
      return getProductName()
    },
    isApp () {
      return /app/.test(this.productName)
    },
    isRiv () {
      return this.productName === 'riv'
    },
    hideFooter () {
      return this.isApp || (this.isRiv && getReseller() !== 'default')
    }
  },
  created () {
    let theme = 'default'
    const product = getProductName()
    if (product !== 'mnet') {
      // handle favicon
      const favicon = document.getElementById('favicon')
      switch (product) {
        case 'riv':
          favicon.href = window.location.origin + '/riv_favicon.ico'
          theme = getReseller()
          break
        case 'lyber':
          favicon.href = window.location.origin + '/lyber_favicon.ico'
          break
      }
    }
    document.documentElement.className = theme // set theme
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/materials.scss';
@import '@/assets/styles/resellers.scss';
</style>

<style lang="scss" scoped>
.main {
  background-color: $white;

  @include media-breakpoint-up(sm) {
    max-width: map-get($map: $grid-breakpoints, $key: "sm");
    margin: 0 auto 30px auto;
  }

  @include media-breakpoint-up(lg) {
    box-shadow: 0px 8px 12px 0 gray("300");
  }
  .main-container {
    padding: 0 $spacer*2 $spacer*2 $spacer*2;
  }
}

.footer {
  color: $gray-600;
  font-size: $small-font-size;
}
</style>
