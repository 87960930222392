<template>
  <spinner :status="status" :depth="depth" :size="size" />
</template>

<script>
import Spinner from 'vue-spinner-component/src/Spinner'

export default {
  name: 'TheSpinner',
  components: { Spinner },
  props: {
    depth: {
      default: 10
    },
    size: {
      default: 55
    },
    status: {
      default: true,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.sl-spinner {
  margin: 20px auto;
  border-left-color: #e84c1d !important;
}
</style>
