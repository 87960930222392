import Api from '@/services/Api'
import i18n from '@/i18n'
import { alphaCompare } from '@/utils'
import { getProductName } from '@/utils/url'
import {
  E_VALIDATION,
  LYBER_FORGOTTEN_PWD_CONTEXT,
  SITE_FORGOTTEN_PWD_CONTEXT,
  VALIDATION_STATUS_OK,
  VALIDATION_STATUS_KO,
  VALIDATION_STATUS_ABUSED,
  VALIDATION_STATUS_EXISTS
} from '@/constants'

export default {
  checkProxies ({ type, value }) {
    const context = getProductName() === 'lyber' ? LYBER_FORGOTTEN_PWD_CONTEXT : SITE_FORGOTTEN_PWD_CONTEXT
    return Api.get('users/check-proxies', {
      params: {
        [type]: value,
        context
      }
    }).then(res => {
      if (res[type] === VALIDATION_STATUS_EXISTS) { // check phone or email success
        return res.guid
      } else {
        const err = new Error(`Invalid ${type} ${value} (code: ${res[type]})`)
        err.id = E_VALIDATION
        throw err
      }
    })
  },
  sendValidation ({ type, value, guid, resend }) {
    return Api.get('users/send-validation', {
      params: {
        type,
        value,
        guid,
        protocol_version: 1,
        lang: i18n.locale,
        resend: resend === true
      }
    }).then(res => {
      if (![VALIDATION_STATUS_OK, VALIDATION_STATUS_ABUSED].includes(res.sent)) {
        const err = new Error(`Could not send PIN to ${type} ${value} (code: ${res.sent})`)
        err.id = E_VALIDATION
        throw err
      } else {
        return res
      }
    })
  },
  checkValidation ({ type, value, guid, code }) {
    return Api.get('users/check-validation', {
      params: {
        type,
        value,
        guid,
        code
      }
    }).then(res => {
      if (res.validated === VALIDATION_STATUS_KO) {
        const err = new Error(`Wrong PIN: ${code}`)
        err.id = E_VALIDATION
        throw err
      }
      return res
    })
  },
  validationAction (params = {}) {
    return Api.post('users/validation-action', params)
  },
  getCountryPrefixes () {
    return Api.get('mnet/sms-auth-prefixes').then(res => {
      return res.prefixes.sort((a, b) => alphaCompare(a.name, b.name))
    })
  }
}
