import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { getValueByName } from '@/utils/url'

export async function getFingerprintData () {
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  const data = {
    web_user_agent: navigator.userAgent,
    web_visitor_id: result.visitorId,
    web_vendor: result.components.vendor.value,
    web_platform: result.components.platform.value,
    web_language: result.components.languages.value[0][0],
    web_timezone: result.components.timezone.value
  }

  const deviceId = getValueByName('device_id')
  if (deviceId !== '') {
    delete data.web_visitor_id
    data.phone_device_id = deviceId

    const phoneOs = getValueByName('os')
    if (phoneOs !== '') {
      data.phone_os = phoneOs
    }

    const phoneOsVersion = getValueByName('os_version')
    if (phoneOsVersion !== '') {
      data.phone_os_version = phoneOsVersion
    }

    const phoneHwModel = getValueByName('hw_model')
    if (phoneHwModel !== '') {
      data.phone_hw_model = phoneHwModel
    }

    const phoneLocale = getValueByName('locale')
    if (phoneLocale !== '') {
      data.phone_locale = phoneLocale
    }
  }
  return data
}
