<template>
  <div>
    <b-button-close @click="$emit('chooseCountryPrefix', currentCountryPrefix)"/>
    <h3>Prefisso nazionale</h3>
    <b-input-group size="lg">
      <b-input-group-text slot="prepend">
        <font-awesome-icon icon="search" />
      </b-input-group-text>
      <b-form-input type="search" v-model="search" v-focus />
    </b-input-group>
    <div v-if="!countries.length" class="d-flex justify-content-center m-3">
      <b-spinner label="loading"/>
    </div>
    <b-list-group v-else>
      <b-list-group-item
        v-for="country in filteredCountries"
        :key="country.code"
        button
        @click="$emit('chooseCountryPrefix', country)"
      >
        <b-img :src="getFlag(country.code)" width="28" height="20" />
        <span class="ml-3 text-muted">+{{ country.prefix }}</span>
        <span class="ml-2">{{ country.name }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { matchString } from '@/utils'

export default {
  name: 'PrefixChoice',
  data () {
    return {
      search: ''
    }
  },
  props: {
    currentCountryPrefix: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      countries: 'countryPrefixes'
    }),
    filteredCountries () {
      const filteredCountries = this.countries.filter(country => {
        return matchString(this.search, ['+' + country.prefix, country.code, country.name])
      })
      // move current prefix to top
      const indexOfCurrent = filteredCountries.findIndex(el => el.code === this.currentCountryPrefix.code)
      if (indexOfCurrent > -1) {
        filteredCountries.splice(indexOfCurrent, 1)
        filteredCountries.unshift(this.currentCountryPrefix)
      }
      return filteredCountries
    }
  },
  methods: {
    ...mapActions(['getCountryPrefixes']),
    getFlag (countryCode) {
      return `/img/flags/${countryCode}@1x.png`
    }
  },
  created () {
    this.getCountryPrefixes()
  }
}
</script>
