/* eslint no-console:"off" */
const isProd = process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV !== 'staging'

export default {
  log (...params) {
    if (!isProd) {
      console.log(...params)
    }
  },
  error (...params) {
    console.error(...params)
  },
  errorPrivate (...params) {
    if (!isProd) {
      console.error(...params)
    }
  }
}
