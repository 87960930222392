<template>
  <div>
    <Spinner :status="showSpinner" />
    <Alert :i18nData="asyncError" />
  </div>
</template>
<script>
import LoginService from '@/services/LoginService'
import { logError } from '@/utils/error'
import { getFingerprintData } from '@/utils/fingerprint'
import { E_SERVER_HTTP } from '@/constants'

export default {
  name: 'FacebookAuth',
  data () {
    return {
      accessToken: '',
      asyncError: null,
      showSpinner: true
    }
  },
  methods: {
    getAccessToken () {
      const self = this
      // eslint-disable-next-line no-undef
      FB.login(function (response) {
        if (response.status !== 'connected') {
          self.asyncError = { key: 'pages.facebookAuth.errorToken' }
        } else {
          self.accessToken = response.authResponse.accessToken
          return self.login()
        }
      }, { scope: 'email' })
    },
    async login () {
      const fingerprint = await getFingerprintData()
      return LoginService.facebookLogin({ token: this.accessToken, fingerprint }).then(data => {
        if (data.redirect_uri) {
          window.location.href = data.redirect_uri
        }
      }).catch(err => {
        this.showSpinner = false
        if (err.id === E_SERVER_HTTP) {
          this.asyncError = { key: 'pages.facebookAuth.errorLogin' }
          logError(err)
        } else {
          throw err
        }
      })
    }
  },
  created () {
    const self = this
    window.fbAsyncInit = function () {
      if (!process.env.VUE_APP_FACEBOOK_KEY) {
        self.asyncError = { key: 'pages.facebookAuth.errorSdkKey' }
        return
      }

      if (!process.env.VUE_APP_FACEBOOK_SDK_VERSION) {
        self.asyncError = { key: 'pages.facebookAuth.errorSdkVersion' }
        return
      }
      // eslint-disable-next-line no-undef
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_KEY,
        xfbml: true,
        version: process.env.VUE_APP_FACEBOOK_SDK_VERSION
      })

      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function (response) {
        if (response.status !== 'connected') {
          return self.getAccessToken()
        } else {
          self.accessToken = response.authResponse.accessToken
          return self.login()
        }
      })
    };

    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) { return }
      const js = d.createElement(s); js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  }
}
</script>
