import Vue from 'vue'
import logger from '@/utils/logger'
import i18n from '@/i18n'
import { E_NETWORK, E_SERVER_HTTP } from '@/constants'

export function handleError (err) {
  if (err.id === E_NETWORK) {
    Vue.toasted.error(i18n.t('errors.connection'))
  } else {
    Vue.toasted.error(i18n.t('errors.unexpected'))
  }
}

export function logError (err) {
  if (err.id === E_NETWORK) {
    // do not log
  } else if (err.id === E_SERVER_HTTP) {
    logger.error(`[HTTP_ERR] Server responded with: ${err.code} - ${err.message}`)
  } else {
    logger.error(`[ERR_HANDLER] unexpected error (message: ${err.message}, other keys: ${JSON.stringify(err)})`)
    logger.errorPrivate(`[ERR_HANDLER] stack trace: ${err.stack}`)
  }
}

export function handleAndLogError (err) {
  logError(err)
  handleError(err)
}
