<template>
  <div>
    <Spinner :status="showSpinner" />
    <Alert :i18nData="asyncError" />
  </div>
</template>

<script>
import LoginService from '@/services/LoginService'
import InfoService from '@/services/InfoService'
import { buildUrlFromParams } from '@/utils/url'
import { getFingerprintData } from '@/utils/fingerprint'
import { logError } from '@/utils/error'
import {
  GOOGLE_OAUTH_BASE,
  GOOGLE_AUTH_SCOPE,
  GOOGLE_TOKEN_RESPONSE_TYPE,
  E_SERVER_HTTP
} from '@/constants'

import {
  SET_REDIRECT_URI
} from '@/store/mutationTypes'

export default {
  name: 'GoogleAuth',
  data () {
    return {
      accessToken: '',
      asyncError: null,
      showSpinner: true
    }
  },
  methods: {
    getAccessToken () {
      const params = {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        response_type: GOOGLE_TOKEN_RESPONSE_TYPE,
        redirect_uri: [location.protocol, '//', location.host, location.pathname].join(''),
        scope: GOOGLE_AUTH_SCOPE
      }

      window.location.href = buildUrlFromParams(GOOGLE_OAUTH_BASE, params)
    },
    async login () {
      const fingerprint = await getFingerprintData()
      return LoginService.googleLogin({ token: this.accessToken, fingerprint }).then(async res => {
        if (res.redirect_uri) {
          this.$store.commit(SET_REDIRECT_URI, res.redirect_uri)
        }
        let info
        try {
          info = await InfoService.fetchContractInfo()
        } catch (err) {
          if (err.code !== 400) {
            throw err
          }
        }
        if (info && !info.latest_contract) {
          this.$router.push('accept-contract')
        } else {
          this.$store.dispatch('goToRedirectUri')
        }
      }).catch(err => {
        this.showSpinner = false
        if (err.id === E_SERVER_HTTP) {
          this.asyncError = { key: 'pages.googleAuth.error' }
          logError(err)
        } else {
          throw err
        }
      })
    }
  },
  mounted () {
    const params = {}
    this.$route.hash.split('&')
      .map(part => part.replace(/#/, ''))
      .forEach(param => {
        const parts = param.split('=')
        params[parts[0]] = parts[1]
      })

    if (params.access_token !== undefined) {
      this.accessToken = params.access_token
      return this.login()
    } else {
      return this.getAccessToken()
    }
  }
}
</script>
