import Api from '@/services/Api'

export default {
  infoUser () {
    return Api.get('info/user')
  },
  infoMnet () {
    return Api.get('info/mnet')
  },
  fetchContractInfo () {
    return Api.get('mnet/profile')
  },
  setContractInfo (latestContract, unfairTerms) {
    return Api.post('mnet/profile', {
      latest_contract: latestContract,
      unfair_terms: unfairTerms
    })
  }
}
