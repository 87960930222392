<template>
  <div>
    <div v-if="!prefixChoiceOpen">
      <slot :open-list="openPrefixChoice" />
    </div>
    <div v-else>
      <PrefixChoice
        :currentCountryPrefix="currentCountryPrefix"
        @chooseCountryPrefix="chooseCountryPrefix"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PrefixChoice from '@/components/PrefixChoice'
export default {
  name: 'PrefixChoiceLayout',
  components: {
    PrefixChoice
  },
  data () {
    return {
      prefixChoiceOpen: false
    }
  },
  computed: {
    ...mapState([
      'currentCountryPrefix'
    ])
  },
  methods: {
    openPrefixChoice () {
      this.prefixChoiceOpen = true
    },
    chooseCountryPrefix (countryPrefix) {
      this.$store.commit('SET_CURRENT_COUNTRY_PREFIX', countryPrefix)
      this.prefixChoiceOpen = false
    }
  },
  created () {
    this.$store.commit('SET_CURRENT_COUNTRY_PREFIX', {
      code: 'IT',
      prefix: '39',
      name: 'Italia'
    })
  }
}
</script>
