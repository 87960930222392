<template>
  <PrefixChoiceLayout v-slot="{ openList }">
    <Alert :i18nData="asyncError" />
    <h5 class="mt-4 title">{{ $t('pages.recover.title') }}</h5>
    <b-form class="mt-4" @submit.prevent="onSubmit">
      <Toggle
        id="input-type-toggle"
        name="contact-type"
        :optionsText="[$t('pages.recover.radio.mobile'), $t('pages.recover.radio.email') ]"
        :optionsValues="contactOptions"
        v-model="form.contactType"
        class="mb-4"
      />
      <b-form-group v-if="isPhone" class="form-group-floating"
        :description="$t('pages.recover.inputPhone.description')">
        <b-input-group size="lg">
          <b-input-group-text slot="prepend" class="bg-white">
            <span class="fa-clickable" @click="openList">
              <b-img :src="`/img/flags/${currentCountryPrefix.code}@1x.png`" width="28" height="20"></b-img>
              <span class="ml-2 text-muted">+{{ currentCountryPrefix.prefix }}</span>
            </span>
          </b-input-group-text>
          <b-form-input
            id="input-1"
            class="floating-label-input"
            key="1"
            v-model="form.phone"
            type="tel"
            name="tel"
            :placeholder="$t('pages.recover.inputPhone.label')"
            required
            v-focus
          />
          <label for="input-1" class="tel-label">
            {{ $t('pages.recover.inputPhone.label') }}
          </label>
        </b-input-group>
      </b-form-group>
      <b-form-group v-else class="form-group-floating"
        :description="$t('pages.recover.inputEmail.description')">
        <b-form-input
          id="input-2"
          key="2"
          v-model.trim="form.email"
          type="email"
          required
          v-focus
          size="lg"
          :placeholder="$t('pages.recover.inputEmail.label')"
          class="floating-label-input"
        ></b-form-input>
        <label for="input-2">
          {{ $t('pages.recover.inputEmail.label') }}
        </label>
      </b-form-group>
      <b-button
        class="btn-centered rounded-pill"
        type="submit"
        variant="primary"
        :disabled="loading"
      >
        {{ buttonText }}
      </b-button>
    </b-form>
    <div class="d-flex justify-content-center">
      <router-link class="link-centered" to="/login">{{ $t('pages.recover.linkCancel') }}</router-link>
    </div>
  </PrefixChoiceLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  E_VALIDATION,
  TYPE_PHONE,
  TYPE_EMAIL
} from '@/constants'
import Toggle from '@/elements/Toggle'
import PrefixChoiceLayout from '@/components/PrefixChoiceLayout'
import { turnOffZoom } from '@/utils'

export default {
  name: 'Recover',
  components: {
    Toggle,
    PrefixChoiceLayout
  },
  data () {
    return {
      form: {
        contactType: TYPE_PHONE,
        email: '',
        phone: ''
      },
      loading: false,
      asyncError: null,
      contactOptions: [TYPE_PHONE, TYPE_EMAIL]
    }
  },
  computed: {
    ...mapState(['currentCountryPrefix']),
    isPhone () {
      return this.form.contactType === TYPE_PHONE
    },
    buttonText () {
      if (this.isPhone) {
        return this.$t('pages.recover.buttonOkPhone')
      } else {
        return this.$t('pages.recover.buttonOkEmail')
      }
    },
    contactValue () {
      if (this.isPhone) {
        return this.currentCountryPrefix.prefix + this.form.phone
      } else {
        return this.form.email
      }
    }
  },
  watch: {
    form: { // clean async error on new input
      handler () { this.asyncError = null },
      deep: true
    }
  },
  methods: {
    ...mapActions(['sendPin']),
    chooseCountryPrefix (countryPrefix) {
      this.prefixChoiceOpen = false
      this.currentCountryPrefix = countryPrefix
    },
    onSubmit () {
      this.asyncError = null
      this.loading = true
      return this.sendPin({
        type: this.form.contactType,
        value: this.contactValue
      }).then(() => {
        this.$router.push({ name: 'recoverPin' })
      }).catch(err => {
        if (err.id === E_VALIDATION) {
          if (this.isPhone) {
            this.asyncError = {
              key: 'pages.recover.errorPhoneNotValid',
              values: {
                value: '+' + this.currentCountryPrefix.prefix + this.form.phone
              }
            }
          } else {
            this.asyncError = {
              key: 'pages.recover.errorEmailNotValid',
              values: {
                value: this.form.email
              }
            }
          }
        } else {
          throw err
        }
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    turnOffZoom()
  }
}
</script>
